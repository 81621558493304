import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated, selectUserRole } from 'data/user.slice';

function RestrictedRoute({ component: Component, role, ...rest }) {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userRole = useSelector(selectUserRole);

  if(isAuthenticated && userRole === role) {
    return (<Component {...rest} />)
  }
  return window.location.replace('/login')
}

export const AgentRoute = (props) => <RestrictedRoute {...props} role="agent" />;
export const AdminRoute = (props) => <RestrictedRoute {...props} role="admin" />;