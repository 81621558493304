import React from 'react'

export default function AgencyLogo(darkMode = true) {
  return (
    <img
        className={`mx-auto w-32 transition-all duration-300 ${darkMode ? 'grayscale invert' : ''}`}
        src="/pcs-logo.webp"
        alt="Pacific Crest Services"
    />
  )
}
