import React from 'react';
import { Routes, Route  } from "react-router-dom";
import { selectIsAuthenticated, logout, authUser } from '../data/user.slice';
import { connect } from "react-redux";
import Dashboard from '../components/Dashboard';
import { withRouter } from 'middleware/withRouter';
import RestrictedRoute, { AdminRoute, AgentRoute } from 'middleware/restricted';

const AppRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path='/agent' element={<AgentRoute component={Dashboard} />} />
        <Route path='/admin' element={<AdminRoute component={Dashboard} />} />
    </Routes>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    errors: state.errors
  };
}

const connectedRoutes = connect(mapStateToProps, {
  selectIsAuthenticated,
  logout,
  authUser
})(AppRoutes);

export default withRouter(connectedRoutes);