import React, { useState } from 'react';
import Sidebar from 'components/Sidebar'; // Adjust the import path as necessary
import ActionBar from 'components/ActionBar';
import AppBar from 'components/AppBar';
import { navLinks, coreLinks, actionLinks } from '../constants';
import AgencyLogo from 'components/AgencyLogo';

export default function DashboardWrapper({ children }) {
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(false);
  const [isActionBarExpanded, setIsActionBarExpanded] = useState(false);

  const toggleSidebar = () => {
    setIsSideBarExpanded((prevValue) => !prevValue);
  };

  return (
    <div className="flex">
      <AppBar agencyLogo={AgencyLogo} />
      <div>
        <div onMouseEnter={toggleSidebar} onMouseLeave={toggleSidebar}>
          <Sidebar
            isExpanded={isSideBarExpanded}
            navLinks={navLinks}
            coreLinks={coreLinks}
            isActionBarExpanded={isActionBarExpanded}
          />
        </div>
        <div className="content">{children}</div>
      </div>
      <ActionBar actionLinks={actionLinks} onExpand={setIsActionBarExpanded} />
    </div>
  );
}
