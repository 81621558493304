import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './data'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from "react-router-dom";
import Routes from './config/routes';
import { setAuthorizationToken, setCurrentUser } from 'data/user.slice';
import { jwtDecode } from "jwt-decode";

const root = ReactDOM.createRoot(document.getElementById('InteliForge'));

if (localStorage.jwtToken && localStorage.jwtToken !== undefined) {
  setAuthorizationToken(localStorage.jwtToken);
  try {
    store.dispatch(setCurrentUser(jwtDecode(localStorage.jwtToken)));
  } catch (e) {
    store.dispatch(setCurrentUser({}));
  }
}

const createRoutes = () => (
  <Router>
      <Routes />
  </Router>
);

root.render(
  <Provider store={store}>
    {createRoutes()}
  </Provider>,
);

reportWebVitals();
