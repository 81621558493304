import { createSlice } from '@reduxjs/toolkit';
import { apiCall, setTokenHeader } from "../services/api";

const initialState = {
  isAuthenticated: false,
  currentUser: null,
  userRole: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
      state.isAuthenticated = !!Object.keys(action.payload).length;
    },
    logout: (state) => {
      state.currentUser = null;
      state.isAuthenticated = false;
      localStorage.clear();
      setTokenHeader(false);
      window.location.assign('/login');
    }
  }
});

export const { setCurrentUser, logout } = userSlice.actions;

export const authUser = (type, userData) => async (dispatch) => {
  try {
    const { token, role, ...user } = await apiCall("post", `/oauth/${type}`, userData);
    localStorage.setItem("jwtToken", token);
    setTokenHeader(token);
    dispatch(setCurrentUser(user));
    dispatch(setCurrentUser(role));
  } catch (err) {
    console.error(err);
  }
};

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}

// Selectors
export const selectUser = (state) => state.user.currentUser;
export const selectUserRole = (state) => state.user.userRole;
export const selectIsAuthenticated = (state) => state.user.isAuthenticated;

export default userSlice.reducer;