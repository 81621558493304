import React from 'react';
import {
  HomeIcon,
  MagnifyingGlassIcon,
  CogIcon,
  PhoneArrowDownLeftIcon,
  WrenchIcon,
  BellIcon,
  ArrowsRightLeftIcon,
  SparklesIcon,
  ArrowTrendingUpIcon,
  PlusIcon,
  CalendarIcon,
  PhoneIcon,
  GlobeAmericasIcon
} from '@heroicons/react/24/outline';

const navLinks = [
    { name: 'Home', icon: <HomeIcon className="w-6 h-6" /> },
    { name: 'Search', icon: <MagnifyingGlassIcon className="w-6 h-6" /> },
    { name: 'Service', icon: <CogIcon className="w-6 h-6" /> },
    { name: 'Sales', icon: <SparklesIcon className="w-6 h-6" /> },
    { name: 'Analytics', icon: <ArrowTrendingUpIcon className="w-6 h-6" /> },
    { name: 'Engage(CRM)', icon: <ArrowsRightLeftIcon className="w-6 h-6" /> }
  ];
  
  const coreLinks = [
    { name: 'Notifications', icon: <BellIcon className="w-6 h-6" /> },
    { name: 'Settings', icon: <WrenchIcon className="w-6 h-6" /> },
    { name: 'Help', icon: <PhoneArrowDownLeftIcon className="w-6 h-6" /> }
  ];
  
  const actionLinks = [
    { name: 'Add Widget', icon: <PlusIcon className="w-4 h-4 mx-2 cursor-pointer" /> },
    { name: 'Calendar', icon: <CalendarIcon className="w-4 h-4 mx-2 cursor-pointer" /> },
    { name: 'Phone', icon: <PhoneIcon className="w-4 h-4 mx-2 cursor-pointer" /> },
    { name: 'IntelliForge', icon: <GlobeAmericasIcon className="w-4 h-4 mx-2 cursor-pointer" /> }
  ];

export {
    navLinks,
    coreLinks,
    actionLinks
}