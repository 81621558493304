import React, { useState, useEffect } from 'react';

export default function ActionBar({ actionLinks, onExpand }) {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    onExpand(isExpanded);
  }, [isExpanded, onExpand]);

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 text-gray-500 flex justify-end shadow-inner transition-all duration-300 ${
        isExpanded ? '' : 'h-2'
      }`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      {actionLinks.map((link, index) => (
        <div key={index} className="flex flex-col items-center py-1 border-r-2">
          {link.icon}
        </div>
      ))}
    </div>
  );
}
